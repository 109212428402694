import request from '@/utils/requestV2'
const qs = require('qs')

// 查询可用素材批次
export function getMaterialBatch (data) {
  return request({
    url: '/ooh-inventory/inventory/manage/getMaterialBatch',
    method: 'post',
    data: qs.stringify(data)
  })
}
// 获取素材画面的分类
export function getMaterialCategory () {
  return request({
    url: '/ooh-inventory/inventory/manage/getMaterialCategory',
    method: 'get'
  })
}
// 人工进行素材的损耗申报
export function saveManualScrapRecord (data) {
  return request({
    url: '/ooh-inventory/inventory/manage/saveManualScrapRecord',
    method: 'post',
    data: qs.stringify(data)
  })
}
// 查询素材画面申报操作记录明细
export function listOperateApprovalRecord (data) {
  return request({
    url: '/ooh-inventory/inventory/manage/listOperateApprovalRecord',
    method: 'post',
    data: qs.stringify(data)
  })
}
