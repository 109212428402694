<template>
    <div>
        <Row class="p-b-10" :gutter="8">
            <i-col span="4">
                <Select size="small" v-model="query.category" placeholder="选择画面类型" clearable @on-change="handleSearch">
                    <Option v-for="item in materialCategory" :value="item.category" :key="item.category">{{ item.categoryName }}</Option>
                </Select>
            </i-col>
            <i-col span="4">
              <DatePicker size="small" placeholder="制作时间" v-model="createDate" type="daterange" style="width:100%" @on-change="handleSearch"></DatePicker>
            </i-col>
            <i-col span="4">
                <Input v-model="query.fileName" placeholder="请输入画面名称..." size="small" clearable/>
            </i-col>
            <i-col span="4">
                <Input v-model="query.stationName" placeholder="请输入站点名称..." size="small" clearable/>
            </i-col>
            <i-col span="4">
                <Button size="small" type="primary" icon="ios-search" @click="handleSearch">搜索</Button>
            </i-col>
            <!-- <i-col span="4" class="text-right">
                显示全部画面：
                <i-switch v-model="query.status" @on-change="handleSearch">
                    <span slot="open">开</span>
                    <span slot="close">关</span>
                </i-switch>
            </i-col> -->
        </Row>

        <Table stripe size="small" :loading="tableLoading" :data="picturesData" :columns="picturesColumns" @on-sort-change="handleTableSort"></Table>
        <div class="paging_style">
            <Page size="small" show-total show-elevator :current="query.pageNum" :page-size="query.pageSize" :total="total" @on-change="handlePageNumberChange"></Page>
        </div>

        <Modal v-model="visiblePreview" width="1200" :footer-hide="true">
            <p slot="header" class="text-center" >{{fileName}}</p>
            <img v-if="visiblePreview" :src="imageUrl+'?x-oss-process=image/resize,w_968'" style="width: 100%">
        </Modal>

        <Modal v-model="modalCreateMaterial" title="损耗申报" width="800" :footer-hide="true">
          <template v-if="chooseMaterialBean">
            <Row :gutter="8">
              <i-col span="8">
                <span class="title">作业画面：</span>
                <a @click="handleReviewImg"><img style="width:150px" :src="chooseMaterialBean.fileKey + '?x-oss-process=image/resize,w_150'"/></a>
              </i-col>
              <i-col span="16">
                <Row :gutter="8">
                  <i-col span="12">
                    <span class="title">画面名称：</span>{{chooseMaterialBean.fileName}}
                  </i-col>
                  <i-col span="12">
                    <span class="title">画面类型：</span>{{chooseMaterialBean.categoryName}}
                  </i-col>
                  <i-col span="12" class="p-t-10">
                    <span class="title">生产批次：</span>{{chooseMaterialBean.code}}
                  </i-col>
                  <i-col span="12" class="p-t-10">
                    <span class="title">资源类型：</span>{{chooseMaterialBean.resourceTypeName}}（{{chooseMaterialBean.width}} * {{chooseMaterialBean.height}}）
                  </i-col>
                  <i-col span="12" class="p-t-10">
                    <span class="title">制作时间：</span>{{chooseMaterialBean.createTime}}
                  </i-col>
                  <i-col span="12" class="p-t-10">
                    <span class="title">报废日期：</span>{{chooseMaterialBean.scrapDateTime}}
                  </i-col>
                  <i-col span="12" class="p-t-10">
                    <span class="title">供应商：</span>{{chooseMaterialBean.supplierName}}
                  </i-col>
                </Row>
              </i-col>
            </Row>
            <Divider dashed />
            <Row class="p-b-5">
              <i-col span="3"><span class="title">仓库报废数量：</span></i-col>
              <i-col span="21">
                <InputNumber v-model="submitBean.inventoryScrapNum" size="small" :max="chooseMaterialBean.remainNum" :min="0"></InputNumber>
                <span class="text-orange m-l-10">(最大可申报数量 {{chooseMaterialBean.remainNum}})</span>
              </i-col>
            </Row>
            <!-- <Row class="p-b-5">
              <i-col span="3"><span class="title">报废在刊点位：</span></i-col>
              <i-col span="21">
                <CheckboxGroup v-model="checkResourceCodes">
                    <Checkbox v-for="station in chooseMaterialBean.stationList" :key="station.resourceId" :label="station.resourceId">
                      {{station.stationName}} {{station.resourceCode}}
                    </Checkbox>
                </CheckboxGroup>
              </i-col>
            </Row> -->
            <Row class="m-t-10">
              <i-col span="3"><span class="title">备注：</span></i-col>
              <i-col span="21">
                <Input v-model="submitBean.remark" maxlength="200" :rows="4" show-word-limit type="textarea" placeholder="请输入申报备注..." style="width: 500px" />
              </i-col>
            </Row>
            <Row class="m-t-10">
              <i-col span="3"><span class="title">一次性画面：</span></i-col>
              <i-col span="21">
                <i-switch v-model="submitBean.isOnce">
                    <span slot="open">是</span>
                    <span slot="close">否</span>
                </i-switch>
              </i-col>
            </Row>
            <Row class="m-t-20">
              <i-col span="24" class="p-l-50">
                <Button type="success" size="small" class="m-l-10" style="width:100px" @click="handleCreateMaterial">提交申报</Button>
              </i-col>
            </Row>
          </template>
        </Modal>

        <Modal v-model="modalOperation" width="800" :footer-hide="true">
            <p slot="header" class="text-center" >{{operationTitle}}</p>
            <Table stripe size="small" :data="operationList" :columns="operationColumns"></Table>
        </Modal>
    </div>
</template>

<script>
import { getMaterialCategory, getMaterialBatch, saveManualScrapRecord, listOperateApprovalRecord } from '@/api/inventory/manage'
const MAX_CELL_ROW = 2

export default {
  data () {
    return {
      materialCategory: [], // 画面类型清单
      createDate: [],

      picturesData: [],
      picturesColumns: [
        { title: '画面名称', key: 'fileName' },
        {
          title: '作业画面',
          render: (h, params) => {
            return h('img', {
              domProps: {
                src: params.row.fileKey + '?x-oss-process=image/resize,w_64'
              },
              on: {
                click: () => {
                  this.fileName = `${params.row.fileName}`
                  this.imageUrl = `${params.row.fileKey}`
                  this.visiblePreview = true
                }
              },
              style: {
                width: '64px',
                cursor: 'pointer'
              }
            })
          }
        },
        { title: '画面类型', key: 'categoryName' },
        {
          title: '资源类型',
          width: 120,
          render: (h, data) => {
            return h('div', [
              h('span', data.row.resourceTypeName),
              h('br'),
              h('span', '(' + data.row.width + ' * ' + data.row.height + ')')
            ])
          }
        },
        { title: '生产批次', key: 'code' },
        {
          title: '仓库数量/在刊数量/总数量',
          width: 180,
          render: (h, data) => {
            return h('span', data.row.remainNum + ' / ' + data.row.issueNum + ' / ' + data.row.originalNum)
          }
        },
        {
          title: '在刊站点',
          width: 250,
          render: (h, data) => {
            const stationSpan = []
            data.row.stationList.forEach(element => {
              stationSpan.push(
                h('p', element.stationName + ' ' + element.resourceCode)
              )
            })

            let showMore
            const tooMany = data.row.stationList.length - MAX_CELL_ROW > 1
            const simple = tooMany ? stationSpan.slice(0, MAX_CELL_ROW) : stationSpan.slice(0)
            if (tooMany) {
              showMore = h('a', {
                on: {
                  click: () => {
                    data.row.showAll = !data.row.showAll
                  }
                }
              }, [
                h('Icon', {
                  props: {
                    type: data.row.showAll ? 'ios-arrow-down' : 'ios-arrow-up',
                    color: '#ed4014',
                    size: 'large'
                  }

                }),
                h('span', { style: { color: '#ed4014' } }, data.row.showAll ? '显示更多' : '隐藏')

              ])
            }

            simple.push(showMore)
            stationSpan.push(showMore)
            return h('div', data.row.showAll ? simple : stationSpan)
          }
        },
        { title: '制作时间', key: 'createTime', sortable: 'custom' },
        // { title: '预计报废日期', key: 'scrapDateTime' },
        {
          title: '操作',
          render: (h, params) => {
            const operationButtons = []
            if (params.row.remainNum > 0) { // 仓库数量大于0
              operationButtons.push(h('a', {
                style: { marginRight: '5px' },
                on: {
                  click: () => {
                    this.modalCreateMaterial = true
                    this.chooseMaterialBean = params.row
                    this.submitBean.code = params.row.code
                    this.submitBean.inventoryScrapNum = params.row.remainNum
                    this.submitBean.remark = ''
                    this.submitBean.isOnce = false
                    this.checkResourceCodes = params.row.stationList.map(x => x.resourceId)
                  }
                }
              }, '损耗申报'))
            }

            operationButtons.push(h('a', {
              on: {
                click: () => {
                  this.handleLoadOperateRecord(params.row)
                }
              }
            }, '历史记录'))

            return h('div', operationButtons)
          }
        }
      ],

      query: {
        publisherId: this.$store.getters.token.userInfo.publisherId,
        category: null,
        ascending: false,
        fileName: '',
        stationName: '',
        status: true,
        pageSize: 10,
        pageNum: 1,
        startDate: '',
        endDate: ''
      },
      total: 0,
      tableLoading: false,

      visiblePreview: false,
      fileName: '',
      imageUrl: '',

      modalCreateMaterial: false,
      chooseMaterialBean: null,
      checkResourceCodes: [], // 选中的所有资源ID
      submitBean: {
        publisherId: this.$store.getters.token.userInfo.publisherId,
        code: 0,
        inventoryScrapNum: 0,
        isOnce: false,
        remark: '',
        resourceCodes: [],
        userId: this.$store.getters.token.userInfo.userId
      },
      operationList: [],
      operationColumns: [
        { title: '损耗类型', key: 'typeName' },
        { title: '损耗批次', key: 'code' },
        { title: '报废仓库数量', key: 'inventoryScrapNum' },
        { title: '操作人', key: 'userName' },
        { title: '操作时间', key: 'operateTime' },
        { title: '备注', key: 'remark' }
      ],
      modalOperation: false,
      operationTitle: ''
    }
  },
  created () {
    this.initPageData()
  },
  methods: {
    initPageData () {
      getMaterialCategory().then(res => {
        this.materialCategory = res
      })

      this.loadMaterialData()
    },
    loadMaterialData () {
      this.tableLoading = true
      getMaterialBatch(this.query).then(res => {
        this.picturesData = []
        res.list.forEach(element => {
          this.picturesData.push(Object.assign({}, element, { showAll: true }))
        })

        this.total = res.totalRow
        this.tableLoading = false
      })
    },
    handleSearch () {
      this.query.startDate = this.createDate[0]
      this.query.endDate = this.createDate[1]
      this.query.pageNum = 1
      this.loadMaterialData()
    },
    handleCreateMaterial () {
      // this.submitBean.resourceCodes = ''
      // let curStation
      // this.checkResourceCodes.forEach(element => {
      //   curStation = this.chooseMaterialBean.stationList.find(x => x.resourceId === element)
      //   if (curStation) {
      //     this.submitBean.resourceCodes += curStation.stationName
      //     this.submitBean.resourceCodes += ':'
      //     this.submitBean.resourceCodes += curStation.resourceCode
      //     this.submitBean.resourceCodes += ','
      //   }
      // })

      saveManualScrapRecord(this.submitBean).then(res => {
        this.modalCreateMaterial = false
        this.$Notice.success({ desc: '损耗申报成功！' })
        this.loadMaterialData()
      })
    },
    handleReviewImg () {
      this.fileName = this.chooseMaterialBean.fileName
      this.imageUrl = this.chooseMaterialBean.fileKey
      this.visiblePreview = true
    },
    handleLoadOperateRecord (materialBean) {
      const postData = {
        publisherId: this.$store.getters.token.userInfo.publisherId,
        code: materialBean.code
      }

      listOperateApprovalRecord(postData).then(res => {
        this.operationList = res
        this.operationTitle = materialBean.fileName + ' 损耗记录'
        this.modalOperation = true
      })
    },
    handleTableSort (column) {
      this.query.pageNum = 1
      this.query.ascending = (column.order === 'asc')
      this.loadMaterialData()
    },
    handlePageNumberChange (page) {
      this.query.pageNum = page
      this.loadMaterialData()
    }
  }
}
</script>
